<template>
  <div>
    <!--Top Page Image Viewer & Contact Methods-->
    <top-page />

    <v-container
      fluid
      grid-list-md
    >
      <v-layout
        row
        wrap
      >
        <v-flex
          d-flex
          xs12
          sm9
        >
          <v-card
            flat
            color="grey lighten-5"
          >
            <v-card-title
              primary
              class="headline font-weight-bold secondary--text"
            >
              About
              Us
            </v-card-title>
            <v-card-text class="subtitle-1">
              We provide contract embroidery to the asi, ppai, screen printing,
              embroidery and trade industry. We operate 150 embroidery heads and specialize in
              large and small orders as well as fulfillment programs with production 17 hours
              daily.  We embroider on apparel, hats, accessories and more. we offer quicker
              turn-around times than most in the industry. we are your production partner.
            </v-card-text>
            <v-card-text class="subtitle-1">
              We view our relationship w/ our clients as a partnership, rather than a distant b2b transaction! We are here to serve you, help your business grow, and to bring dependability and service to the wearables and promotional productions industry.
            </v-card-text>
            <v-card-text class="subtitle-1">
              - iDex International Family
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex
          d-flex
          xs12
          sm3
        >
          <v-card
            flat
            color="secondary"
            dark
          >
            <v-card-text>GROUP PHOTO???</v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import TopPage from '@/components/TopPage';

export default {
  name: 'About',
  computed: {
    ...mapState({
      usersStore: (state) => state.usersStore,
    }),
  },
  components: {
    TopPage,
  },
  data() {
    return {

    };
  },
};
</script>

<style scoped>
  button {
    outline: none;
  }
</style>
