<template>
  <div>
    <v-carousel
      hide-delimiters
      pa-0
      ma-0
      height="350"
    >
      <v-carousel-item
        v-for="(item,i) in items"
        :key="i"
        :src="item.src"
      />
    </v-carousel>

    <v-toolbar
      dark
      flat
      color="cyan lighten-1"
    >
      <v-toolbar-title class="headline white--text">
        800.362.7643
      </v-toolbar-title>

      <v-spacer />

      <v-btn
        v-for="(contact,ii) in contactMethods"
        :key="ii"
        icon
        :href="contact.url"
        target="blank"
      >
        <v-icon
          medium
          color="white"
        >
          {{ contact.icon }}
        </v-icon>
      </v-btn>
    </v-toolbar>
  </div>
</template>

<script>
export default {
  name: 'TopPage',
  data() {
    return {
      items: [
        {
          src: './img/index1.jpg',
        },
        {
          src: './img/index2.jpg',
        },
      ],
      contactMethods: [
        {
          icon: 'far fa-at',
          url: '',
        },
        {
          icon: 'fab fa-facebook-f',
          url: 'https://www.facebook.com/idexint',
        },
        {
          icon: 'fab fa-twitter',
          url: 'https://twitter.com/iDexint',
        },
        {
          icon: 'fab fa-instagram',
          url: 'http://instagram.com/idexint',
        },
        {
          icon: 'fab fa-vimeo-v',
          url: 'http://vimeo.com/user24674169',
        },
      ],
    };
  },
};
</script>

<style scoped>
  button {
    outline: none;
  }
</style>
